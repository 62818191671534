// import React, { useEffect, useState } from "react";
// import logo from "../assets/images/logo_rm_nxg-v1.png";
// // import TitleSponser from "../assets/images/title-sponsor-logo-v1.png";
// import TitleSponser from "../assets/images/title-sponsor.png";
// import {
// 	Button,
// 	Card,
// 	Col,
// 	Container,
// 	Form,
// 	InputGroup,
// 	Row,
// 	Spinner,
// } from "react-bootstrap";
// import Phoneimg from "../assets/images/Phone.png";
// import Emailimg from "../assets/images/Email.png";
// import homeimg from "../assets/images/LoginHome.png";
// import { useFormik } from "formik";
// import AxiosRequest from "../AxiosRequest";
// import { handleNValue } from "../utils/handleNvalue";
// import { toast } from "react-hot-toast";
// import * as Yup from "yup";
// import PhoneInput, {
// 	isPossiblePhoneNumber,
// 	parsePhoneNumber,
// 	isValidPhoneNumber,
// } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
// import OTPInput, { ResendOTP } from "otp-input-react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import OverlayLoader from "../components/OverlayLoader";

// const validationSchema = Yup.object().shape({
// 	mobileNumber: Yup.string()
// 		.test("valid-phone", "Invalid phone number", (value) => {
// 			return (
// 				!value ||
// 				(isValidPhoneNumber(value || "") &&
// 					isPossiblePhoneNumber(value || ""))
// 			);
// 		})
// 		.required("This field is required."),
// 	email: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.email("Email must be in valid format")
// 		.nullable(),
// });
// console.log("production");
// const Login = () => {
// 	const param = useParams();
// 	const navigate = useNavigate();
// 	const location = useLocation();
// 	const [otp, setOtp] = useState("");
// 	const [isloading, setIsLoading] = useState(false);

// 	const formik = useFormik({
// 		initialValues: {
// 			mobileNumber: "",
// 			email: "",
// 		},
// 		validationSchema,
// 		onSubmit: async (values, { setSubmitting, resetForm }) => {
// 			try {
// 				// if (!values.mobileNumber && !values.email) {
// 				//   toast.error("At least one field is required");
// 				//   return false;
// 				// }
// 				const payload = {};
// 				if (values.mobileNumber) {
// 					const parsedNumber = parsePhoneNumber(values.mobileNumber);
// 					if (parsedNumber) {
// 						payload[
// 							"countryCode"
// 						] = `+${parsedNumber.countryCallingCode}`;
// 						payload["mobileNumber"] = parsedNumber.nationalNumber;
// 					}
// 				} else {
// 					payload["email"] = values.email;
// 				}
// 				const { data } = await AxiosRequest.post(`users/request-otp`, {
// 					...payload,
// 				});
// 				toast.success(data.message);
// 				resetForm();
// 				navigate(`/login/verification`, { state: { ...payload } });
// 			} catch (err) {
// 				console.log(err);
// 				toast.error(
// 					err?.response?.data?.message ||
// 						err?.message ||
// 						"Something went wrong"
// 				);
// 			}
// 		},
// 	});

// 	const handleVerifyOtp = async (_otp) => {
// 		const utm_source = localStorage.getItem("utm_source");
// 		const utm_medium = localStorage.getItem("utm_medium");
// 		const utm_campaign = localStorage.getItem("utm_campaign");

// 		try {
// 			setIsLoading(true);
// 			if (!_otp || _otp.length !== 6) {
// 				toast.error("Please enter valid 6 digit otp");
// 				return false;
// 			}
// 			const payload = { ...location.state, otp: _otp, isWeb: true };

// 			if (utm_source) payload["utm_source"] = utm_source;
// 			if (utm_medium) payload["utm_medium"] = utm_medium;
// 			if (utm_campaign) payload["utm_campaign"] = utm_campaign;

// 			const { data } = await AxiosRequest.post(`users/verify-otp`, {
// 				...payload,
// 			});

// 			localStorage.setItem("userToken", data.token);
// 			localStorage.setItem("user", JSON.stringify(data.data));

// 			// if (!data?.data?.persona?._id) {
// 			await AxiosRequest.post(`users/update-user-persona`, {
// 				uniqueCode: "WMUOKpyAIe", // VG SIDDHART MEMORIAL QUIZ
// 			});
// 			// }

// 			setOtp("");
// 			toast.success("Login successful");
// 			if (data?.data?.isVerified) navigate(`/`);
// 			else {
// 				navigate(`/update-profile`);
// 				localStorage.removeItem("utm_source");
// 				localStorage.removeItem("utm_medium");
// 				localStorage.removeItem("utm_campaign");
// 			}
// 		} catch (err) {
// 			console.log(err);
// 			toast.error(
// 				err?.response?.data?.message ||
// 					err?.message ||
// 					"Something went wrong"
// 			);
// 		} finally {
// 			setIsLoading(false);
// 		}
// 	};

// 	const handleResendOtp = async (e) => {
// 		try {
// 			setOtp("");
// 			setIsLoading(true);
// 			const payload = { ...location.state };
// 			const { data } = await AxiosRequest.post(`users/resend-otp`, {
// 				...payload,
// 			});
// 			toast.success(data.message);
// 		} catch (err) {
// 			console.log(err);
// 			toast.error(
// 				err?.response?.data?.message ||
// 					err?.message ||
// 					"Something went wrong"
// 			);
// 		} finally {
// 			setIsLoading(false);
// 		}
// 	};
// 	return (
// 		<>
// 			<div className="loginBG">
// 				<div className="login-page">
// 					<Container>
// 						<div className="login-logo header1">
// 							<img src={logo} alt="" className="logo logo1" />
// 							<span className="vertical-line"></span>
// 							<img
// 								src={TitleSponser}
// 								alt=""
// 								className="logo logo2"
// 							/>
// 						</div>
// 						<Row className="d-flex justify-content-between">
// 							<Col lg={5} sm={12} className="left-section">
// 								<div>
// 									<img
// 										src={homeimg}
// 										alt=""
// 										className="img-fluid"
// 									/>
// 								</div>
// 								<div className="mt-5">
// 									<h2 className="text-center">
// 										Ideate, innovate
// 										<br />
// 										<span>make the world better</span>
// 									</h2>
// 								</div>
// 							</Col>
// 							<Col
// 								lg={5}
// 								sm={12}
// 								className="right-section flipParent"
// 							>
// 								<div
// 									className={`card ${
// 										param?.step === "verification" &&
// 										location.state
// 											? "is-flipped"
// 											: ""
// 									}`}
// 								>
// 									<div className="login-box otp">
// 										{/* <h4 className="fontwhite24 text-center my-5"> */}
// 										<h4 className="fontYellow24 text-center my-5">
// 											OTP
// 										</h4>
// 										<p className="fontwhite14 text-center">
// 											A 6 digit code has been sent <br />
// 											to{" "}
// 											<b>
// 												{location?.state?.email ||
// 													`${location?.state?.countryCode} ${location?.state?.mobileNumber}`}
// 											</b>
// 										</p>
// 										<div className="otp-form mt-5">
// 											<OTPInput
// 												value={otp}
// 												onChange={(value) => {
// 													setOtp(value);
// 													if (value.length === 6)
// 														handleVerifyOtp(value);
// 												}}
// 												autoFocus
// 												OTPLength={6}
// 												otpType="number"
// 												disabled={false}
// 												className="justify-content-center customOtpInput"
// 												secure={false}
// 											/>
// 										</div>
// 										<p className="fontwhite14 mt-3 text-center">
// 											Don�t receive OTP?{" "}
// 											<span
// 												role={"button"}
// 												onClick={() =>
// 													!isloading &&
// 													handleResendOtp()
// 												}
// 												className="otp-span"
// 											>
// 												Resend
// 											</span>
// 										</p>
// 										<Button
// 											type="button"
// 											variant=""
// 											onClick={() =>
// 												!isloading &&
// 												handleVerifyOtp(otp)
// 											}
// 											// disabled={isloading}
// 											// className="next-btn w-100 mt-5 mb-3"
// 											className="yellow-btn w-100 mt-5 mb-3"
// 										>
// 											Submit
// 										</Button>
// 									</div>
// 									<div className="login-box mobile">
// 										{/* <h4 className="fontwhite24 text-center my-5"> */}
// 										<h4 className="fontYellow24 text-center my-5">
// 											LOGIN
// 										</h4>
// 										<Form
// 											onSubmit={formik.handleSubmit}
// 											className="login-form"
// 										>
// 											<Form.Group className="mb-4">
// 												<InputGroup>
// 													<PhoneInput
// 														placeholder="Enter phone number"
// 														value={
// 															formik.values
// 																.mobileNumber
// 														}
// 														onChange={(phone) =>
// 															formik.setFieldValue(
// 																"mobileNumber",
// 																phone
// 															)
// 														}
// 														onBlur={
// 															formik.handleBlur
// 														}
// 														onKeyDown={(e) => {
// 															if (
// 																e.key ===
// 																"Enter"
// 															) {
// 																e.preventDefault();
// 																formik.handleSubmit();
// 															}
// 														}}
// 														name="mobileNumber"
// 														defaultCountry={"IN"}
// 														// className="customPhoneInput w-100"
// 														className="customPhoneInputYellow w-100"
// 													/>
// 												</InputGroup>
// 												{formik.touched.mobileNumber &&
// 												formik.errors.mobileNumber ? (
// 													<Form.Text className="text-danger">
// 														{
// 															formik.errors
// 																.mobileNumber
// 														}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 											{/* <div>
//                         <span className='login-email mb-4'>Or</span>
//                       </div>
//                       <Form.Group className="mb-4">
//                         <InputGroup>
//                           <InputGroup.Text id="basic-addon2"><img src={Emailimg} alt='' className='' /></InputGroup.Text>
//                           <Form.Control
//                             value={formik.values.email}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             name="email"
//                             placeholder="Email ID"
//                             type="email"
//                           />
//                         </InputGroup>
//                         {formik.touched.email && formik.errors.email ? (
//                           <Form.Text className="text-danger">
//                             {formik.errors.email}
//                           </Form.Text>
//                         ) : null}
//                       </Form.Group> */}
// 											<Button
// 												type="submit"
// 												variant=""
// 												// disabled={formik.isSubmitting}
// 												// className="next-btn w-100 my-3"
// 												className="yellow-btn w-100 my-3"
// 											>
// 												{formik.isSubmitting && (
// 													<Spinner
// 														size="sm"
// 														variant="ligth"
// 													/>
// 												)}{" "}
// 												Submit
// 											</Button>
// 										</Form>
// 									</div>
// 								</div>
// 							</Col>
// 						</Row>
// 					</Container>
// 				</div>
// 			</div>
// 			{isloading || formik.isSubmitting ? <OverlayLoader /> : null}
// 		</>
// 	);
// };

// export default Login;
// Above code is login with Mobile and OTP

// Below code is login with Registration ID
import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo_rm_nxg-v1.png";
import TitleSponser from "../assets/images/title-sponsor.png";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
	Spinner,
} from "react-bootstrap";
import Phoneimg from "../assets/images/Phone.png";
import Emailimg from "../assets/images/Email.png";
import homeimg from "../assets/images/LoginHome.png";
// import season3 from "../assets/images/season-3.png";
import season3 from "../assets/images/IFA QUIZ LOGO _page-0001.jpg";
import { useFormik } from "formik";
import AxiosRequest from "../AxiosRequest";
import { handleNValue } from "../utils/handleNvalue";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import PhoneInput, {
	isPossiblePhoneNumber,
	parsePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OverlayLoader from "../components/OverlayLoader";

const validationSchema = Yup.object().shape({
	registrationId: Yup.string().required("This field is required."),
});

console.log("production");
const Login = () => {
	const param = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [otp, setOtp] = useState("");
	const [isloading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			registrationId: "",
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setIsLoading(true);
				const payload = {};
				payload["registrationId"] = values.registrationId;

				const { data } = await AxiosRequest.post(
					`users/check-registrationId`,
					{
						...payload,
					}
				);

				const utm_source = localStorage.getItem("utm_source");
				const utm_medium = localStorage.getItem("utm_medium");
				const utm_campaign = localStorage.getItem("utm_campaign");

				if (data?.data) {
					if (utm_source) payload["utm_source"] = utm_source;
					if (utm_medium) payload["utm_medium"] = utm_medium;
					if (utm_campaign) payload["utm_campaign"] = utm_campaign;

					const user = await AxiosRequest.post(
						`users/create-ccd-reguser`,
						{
							...data?.data,
							isWeb: true,
							payload,
						}
					);

					if (user) {
						toast.success(user?.data?.message);
						resetForm();

						localStorage.setItem("userToken", user?.data?.token);
						localStorage.setItem(
							"user",
							JSON.stringify(user?.data?.data)
						);

						await AxiosRequest.post(`users/update-user-persona`, {
							uniqueCode: "O89by3FVjs", // ASM Group Contest
						});

						// if (user?.data?.data?.isVerified) {
						// 	navigate(`/`);
						// } else {
						// 	navigate(`/update-profile`);
						// }

						navigate(`/`);
					}
				}
			} catch (err) {
				console.log(err);
				toast.error(
					err?.response?.data?.message ||
						err?.message ||
						"Something went wrong"
				);
			} finally {
				setIsLoading(false);
			}
		},
	});

	return (
		<>
			<div className="loginBG">
				<div className="login-page">
					<Container>
						<div className="login-logo header1">
							<img src={logo} alt="" className="logo logo1" />
							<span className="vertical-line"></span>
							<img
								src={TitleSponser}
								alt=""
								className="logo logo2"
							/>
						</div>
						<Row className="d-flex justify-content-between">
							<Col lg={5} sm={12} className="left-section">
								<div className="mt-5 pt-5">
									<img
										src={season3}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div className="mt-5">
									<h2 className="text-center">
										
										<br />
										<span></span>
									</h2>
								</div>
							</Col>
							<Col
								lg={5}
								sm={12}
								className="right-section flipParent"
							>
								<div
									className={`card ${
										param?.step === "verification" &&
										location.state
											? "is-flipped"
											: ""
									}`}
								>
									<div className="login-box mobile">
										{/* <h4 className="fontwhite24 text-center my-5"> */}
										<h4 className="fontYellow24 text-center my-5">
											LOGIN
										</h4>
										<Form
											onSubmit={formik.handleSubmit}
											className="login-form"
										>
											<Form.Group className="mb-4">
												<InputGroup>
													<Form.Control
														placeholder="Enter Registration ID"
														type="text"
														value={
															formik.values
																.registrationId
														}
														onChange={
															formik.handleChange
														}
														onBlur={
															formik.handleBlur
														}
														name="registrationId"
														className="registration-input"
													/>
												</InputGroup>
												{formik.touched
													.registrationId &&
												formik.errors.registrationId ? (
													<Form.Text className="text-danger">
														{
															formik.errors
																.registrationId
														}
													</Form.Text>
												) : null}
											</Form.Group>
											<Button
												type="submit"
												variant=""
												// disabled={formik.isSubmitting}
												className="yellow-btn w-100 my-3"
											>
												{formik.isSubmitting && (
													<Spinner
														size="sm"
														variant="ligth"
													/>
												)}{" "}
												Submit
											</Button>
										</Form>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			{isloading || formik.isSubmitting ? <OverlayLoader /> : null}
		</>
	);
};

export default Login;
